
import { extendTheme } from "@chakra-ui/react";

const config = {
   useSystemColorMode: false,
};

const fonts = {
   heading: "Manrope, sans-serif",
   body: "Manrope, sans-serif",
}

const overrides = {
   fonts,
   config,
};

export default extendTheme(overrides);
