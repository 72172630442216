import "@fontsource/manrope/600.css";
import {
   ChakraProvider,
   Box,
   // Text, Link, VStack, Code, Grid,
   // theme,
   Flex,
   Image,
} from "@chakra-ui/react";
import theme from "./theme";
import { Route, Routes } from "react-router-dom";
import Shed from "./ui/shed";
// import { ColorModeSwitcher } from "./ColorModeSwitcher";
// import { Logo } from "./Logo";

// console.log(theme);

// function App0() {
//    return (
//       <ChakraProvider theme={theme}>
//          <Box textAlign="center" fontSize="xl">
//             <Grid minH="100vh" p={3}>
//                <ColorModeSwitcher justifySelf="flex-end" />
//                <VStack spacing={8}>
//                   <Logo h="40vmin" pointerEvents="none" />
//                   <Text>
//                      Edit <Code fontSize="xl">src/App.js</Code> and save to
//                      reload.
//                   </Text>
//                   <Link
//                      color="teal.500"
//                      href="https://chakra-ui.com"
//                      fontSize="2xl"
//                      target="_blank"
//                      rel="noopener noreferrer"
//                   >
//                      Learn Chakra
//                   </Link>
//                </VStack>
//             </Grid>
//          </Box>
//       </ChakraProvider>
//    );
// }

const App = () => {
   return (
      <ChakraProvider theme={theme}>
         <Routes>
            <Route
               path="/"
               element={
                  <Flex
                     w="100vw"
                     h="100vh"
                     alignItems="center"
                     justifyContent="center"
                     p="40px"
                     flexDir="column"
                     gap="20"
                  >
                     <Image
                        src="/assets/img/twimc1.svg"
                        w={["85%", "80%", "70%", "67%", "50%"]}
                     />
                     <Box fontSize="48px" fontWeight="600">
                        (me)
                     </Box>
                  </Flex>
               }
            />
            <Route path="/ui/shed" element={ <Shed /> } />
         </Routes>
      </ChakraProvider>
   );
};

export default App;
